import styled from "styled-components";
import Theme from "../../constants/theme";
import { mobile } from "../../utils/responsive";

export const Container = styled.div`
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 70vh;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  color: ${Theme.BLACK_COLOR};
  ${mobile({ marginLeft: "auto", width: "96%" })};
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;
export const Items = styled.div`
  display: flex;
  margin-top: 8px;
`;
export const Label = styled.div`
  display: flex;
`;