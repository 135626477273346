import React, { useEffect, useState } from "react";
import ChannelContainer from "../container/channel-container";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { logWebVisited } from "../services/analytics";
import i18n from "../i18n";
import eng from "../assets/united-kingdom.png";
import urdu from "../assets/pakistan.png";

const Home = () => {
  useEffect(() => {
    logWebVisited();
  }, []);

  const handleChangeEng = () => {
    i18n.changeLanguage('en');
    sessionStorage.setItem('lng', 'eng');
  };
  const handleChangeUrdu = () => {
    i18n.changeLanguage('ur');
    sessionStorage.setItem('lng', 'ur');
  };

  return (
    <>
      <Hero />
      <ChannelContainer />
      <Footer />
    </>
  );
};

export default Home;
