import React from "react";
import styled from "styled-components";
import Theme from "../../constants/theme";
import { mobile } from "../../utils/responsive";
import BannerImages from '../../assets/zilhaj.jpg';
import DateObject from "react-date-object";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { useTranslation } from "react-i18next";

var date = new DateObject({ calendar: arabic, locale: arabic_ar });

console.log(date.format()); //١٤٤٢/١٠/٢٩

const Hero = () => {
  const Heading = styled.h1`
    margin-top: 7%;
    text-align: center;
    letter-spacing: 1.5px;
    color: ${Theme.NAVY_BLUE};
    position: relative;
    z-index: 1;
    ${mobile({ marginTop: "25%" })}
  `;

  const ShortDescription = styled.p`
    font-size: 22px;
    color: ${Theme.FONT_GREY};
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 15px;
    position: relative;
    z-index: 1;
    ${mobile({
      fontSize: "18px",
      paddingLeft: "30px",
      paddingRight: "30px",
      lineHeight: "20px",
    })};
  `;
  const BannerImage = styled.img`
    width: 700px;
    height: 600px;
    right: 8px;
    top: 55px;
    position: absolute;
    ${mobile({
      width: "220px",
      height: "220px",
      right: "0px",
      top: "71px"
    })}
  `
  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading>{i18n.language === 'en' ?  t(`title`) : t(`title`)}</Heading>
      <ShortDescription>{i18n.language === 'en' ?  t(`description`) : t(`description`)}</ShortDescription>
      <BannerImage src={BannerImages} alt="bimg"/>
    </>
  );
};

export default Hero;
