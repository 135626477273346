import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { channelData } from './services/data';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            title: "Podcast Network",
            description:"Streaming Platform For Muslim Ummah",
            tagLine:"Streaming Channels For You",
            audioEpisodes: "Audio Episodes",
            episodesBy: "Episodes By"
          },
      },
          ur:{
            translation: {
            title: "پوڈ کاسٹ نیٹ ورک",
            description:"امت مسلمہ کے لیے اسٹریمنگ پلیٹ فارم",
            tagLine:"اسٹریمنگ کی دنیا: اسلامی علماء کے ساتھ",
            audioEpisodes: "آڈیو اقساط",
            episodesBy: "اقساط بذریعہ"
          },
        },
      }
  });

export default i18n;