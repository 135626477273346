import styled from "styled-components";

export const ListDiv = styled.div`
    font-family: 'Noto Nastaliq Urdu', serif !important;
    display: flex;
`;

export const UrduName =  styled.div`
    margin-left: 30px;
    margin-top: -10px;
    display: flex;
`