import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Container } from "./style";
import { Button } from "@mui/material";

const CheckOutForm = () => {
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const handleCheckout = async () => {};

  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Contact Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="Name"
            name="Name"
            label="Full Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="given-name"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email Address"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
            fullWidth
            autoComplete="email-address"
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="contact-number"
            name="contact number"
            label="Contact Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            fullWidth
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            sx={{ display: "flex", marginLeft: "auto" }}
            onClick={handleCheckout}
          >
            CheckOut
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckOutForm;
