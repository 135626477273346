import React, { Suspense, useTransition } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Theme from "../constants/theme";
import { mobile } from "../utils/responsive";
import { channelData } from "../services/data";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Channel = React.lazy(() => import("../components/Channels"));
const ChannelContainer = () => {
  const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;
    ${mobile({ padding: "12px", flexDirection: "column" })}
  `;
  const Heading = styled.h1`
    text-align: center;
    letter-spacing: 2.1px;
    margin-top: 82px;
    margin-bottom: 120px;
    font-size: 52px;
    font-weight: 900;
    color: ${Theme.NAVY_BLUE};
    position: relative;
    z-index: 1;
    ${mobile({ marginTop: "55px", fontSize: "23px", marginBottom: "0px" })}
  `;
  const navigate = useNavigate();
  const navigateChannel = (route: any) => {
    navigate(`/channels/${route}`);
  };

  const CircularLoader = () => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", height: "500px" }}>
        <CircularProgress size={80} sx={{ marginTop: "100px" }} />
      </Box>
    );
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading>{i18n.language === "en" ? t(`tagLine`) : t(`tagLine`)}</Heading>
      <Suspense fallback={<CircularLoader />}>
        <Container>
          {channelData.map((item, index) => (
            <Channel
              key={index}
              channel={item}
              handleNavigation={() => navigateChannel(item.channelRoute)}
              lng={i18n.language}
            />
          ))}
        </Container>
      </Suspense>
    </>
  );
};

export default ChannelContainer;
