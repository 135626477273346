import React, { useEffect, useState, useTransition } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Tracks from "./container/tracks-container";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import "../src/utils/style.css";
import "react-h5-audio-player/lib/styles.css";
import { channelData } from "./services/data";
import { LogChannelVisited, pauseTrack, playTrack } from "./services/analytics";
import Theme from "./constants/theme";
import Checkout from "./screens/Checkout";
import { useTranslation } from "react-i18next";
import Header from "./components/Header";

const Approutes = () => {
  const location = useLocation();
  const channelPath: any = location.pathname.split("/")[2];
  const [channelIndex, setChannelIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(channelIndex);
  const [isSafari, setIsSafari] = useState(false);
  const [currentTrack, setCurrentTrack] = useState("");

  useEffect(() => {
    const index = channelData.findIndex((object) => {
      return object.channelRoute === channelPath;
    });
    if (index !== -1) {
      setChannelIndex(index);
    }
    setIsPlaying(false);
  }, [channelPath]);

  const channelTracks = channelData[channelIndex].mediaTracks;

  useEffect(() => {
    if (location.pathname === "/") {
      setTrackIndex(-1);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/support") {
      setTrackIndex(-1);
      setCurrentTrack("");
    }
  }, [location.pathname]);

  const audiofunction = (mediaName: any) => {
    const index = channelTracks.findIndex((object) => {
      return object.name === mediaName;
    });
    setTrackIndex(index);
    setIsPlaying(true);
  };
  const handleClickPrevious = () => {
    setTrackIndex((currentTrack: any) =>
      currentTrack === 0 ? channelTracks.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack: any) =>
      currentTrack < channelTracks.length - 1 ? currentTrack + 1 : 0
    );
  };
  const audioRef: any = React.useRef(null);
  const [audioReady, setAudioReady] = React.useState(false);

  const handleCanPlayThrough = () => {
    setAudioReady(true);
  };

  const handleClickForward = () => {
    if (audioReady) {
      const currentTime = audioRef.current.audio.current.currentTime;
      audioRef.current.audio.current.currentTime = currentTime + 10;
    }
  };

  const handleClickBackward = () => {
    if (audioReady) {
      const currentTime = audioRef.current.audio.current.currentTime;
      audioRef.current.audio.current.currentTime = currentTime - 10;
    }
  };
  const { i18n } = useTranslation();
  const Tracksrc = channelTracks[trackIndex]?.src;
  const Trackname = channelTracks[trackIndex]?.urduName;
  const title = channelData[channelIndex]?.title;
  const urduTitle = channelData[channelIndex]?.urduTitle;
  const scholarName = i18n.language === "en" ? title : urduTitle;
  const scholarDesc = channelData[channelIndex]?.scholarDescription;
  const urduDescription = channelData[channelIndex]?.urduDescription;
  const channelImg = channelData[channelIndex]?.img;

  useEffect(() => {
    if (location.pathname.includes(channelPath)) {
      LogChannelVisited(scholarName);
    }
  }, [channelPath, location.pathname, scholarName]);

  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    if (isSafari) {
      // eslint-disable-next-line no-restricted-globals
      const shouldRedirect = confirm(
        "We recommend using Chrome and Firefox for the best experience."
      );
      if (shouldRedirect) {
        setIsSafari(false);
      }
    }
  }, [isSafari]);

  useEffect(() => {
    const CurrentTrackName = channelTracks[trackIndex]?.name;
    setCurrentTrack(CurrentTrackName);
  }, [channelTracks, trackIndex]);

  const [lng, setLng] = useState("eng");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChangeEng = () => {
    setLng("eng");
    i18n.changeLanguage("en");
    setAnchorEl(null);
  };
  const handleChangeUrdu = () => {
    setLng("ur");
    i18n.changeLanguage("ur");
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Header
        handleChangeEng={handleChangeEng}
        handleChangeUrdu={handleChangeUrdu}
        handleClick={handleClick}
        handleClose={handleClose}
        open={open}
        anchorEl={anchorEl}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/channels/:channelName"
          element={
            <Tracks
              audioFunction={audiofunction}
              channelTracks={channelTracks}
              title={title}
              urduTitle={urduTitle}
              scholarDesc={scholarDesc}
              channelImg={channelImg}
              currentTrack={currentTrack}
              urduDescription={urduDescription}
              lng={lng}
            />
          }
        />
        <Route path="/support" element={<Checkout />} />
      </Routes>
      {trackIndex >= 0 ? (
        <AudioPlayer
          ref={audioRef}
          className="audioplayer"
          style={{
            borderRadius: "1rem",
            width: "60%",
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translate(-50%, 0)",
            boxShadow: "0 0 3px 0 rgb(0 0 0 / 20%)",
            padding: "25px 15px",
            background: "white",
            zIndex: "100",
          }}
          customAdditionalControls={[
            RHAP_UI.LOOP,
            <Replay10Icon
              onClick={handleClickBackward}
              sx={{
                fontSize: "35px",
                color: `${Theme.DIM_GREY_COLOR}`,
                cursor: "pointer",
              }}
            />,
            <Forward10Icon
              onClick={handleClickForward}
              sx={{
                fontSize: "35px",
                color: `${Theme.DIM_GREY_COLOR}`,
                cursor: "pointer",
              }}
            />,
          ]}
          preload="auto"
          onCanPlayThrough={handleCanPlayThrough}
          autoPlay={isPlaying}
          autoPlayAfterSrcChange={isPlaying}
          src={Tracksrc}
          onPlay={() => playTrack(scholarName)}
          onPause={() => pauseTrack(scholarName)}
          showSkipControls={true}
          showJumpControls={false}
          header={`Now playing: ${scholarName}'s // ${Trackname}`}
          onClickPrevious={handleClickPrevious}
          onClickNext={handleClickNext}
          onEnded={handleClickNext}
          footer
        />
      ) : null}
    </>
  );
};

export default Approutes;
