/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import logoSrc from "../../assets/EhsaanStream.png";
import { mobile } from "../../utils/responsive";
import Theme from "../../constants/theme";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import i18n from "../../i18n";
import enFlag from "../../assets/united-kingdom.png";
import pkFlag from "../../assets/pakistan.png";

const Container = styled.div`
  height: 60px;
  ${mobile({ height: "50px" })}
`;
const Wrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: white;
  box-shadow: 0px 5px 15px -2px grey;
  ${mobile({ padding: "16px 0px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Language = styled.h1`
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: ${Theme.FONT_GREY};
  ${mobile({ display: "none" })};
`;

const Logo = styled.img`
  cursor: pointer;
  width: 300px;
  height: auto;
  position: absolute;
  margin-top: 42px;
  margin-left: -50px;
  ${mobile({
    marginTop: "30px",
    marginLeft: "-20px",
    position: "absolute",
    width: "200px",
    height: "auto",
  })};
`;

const Item = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  cursor: pointer;
  margin-right: 25px;
  color: ${Theme.FONT_GREY};
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

type Props = {
  handleChangeEng: (e: any) => void;
  handleChangeUrdu: (e: any) => void;
  handleClick: (e: any) => void;
  handleClose: () => void;
  open: boolean;
  anchorEl: any;
};

const Header = ({
  handleChangeEng,
  handleChangeUrdu,
  handleClick,
  handleClose,
  open,
  anchorEl,
}: Props) => {
  const navigate = useNavigate();

  const Home = () => {
    navigate("/", { replace: true });
  };

  const handleCheckout = () => {
    navigate("/support", { replace: true });
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Logo onClick={Home} src={logoSrc} />
        </Left>
        <Right>
          <Item onClick={Home}>Home</Item>
          <Item onClick={handleCheckout}>
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: `${Theme.BLACK_COLOR}`,
                cursor: "pointer",
              }}
            >
              <Chip
                label="Support Us"
                color="primary"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Item>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              color: `${Theme.BLACK_COLOR}`,
              textTransform: "none",
              fontWeight: "900",
            }}
          >
            Language
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleChangeEng}>English</MenuItem>
            <MenuItem onClick={handleChangeUrdu}>Urdu</MenuItem>
          </Menu>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Header;
