import mft from '../assets/MuftiTM.jpg';
import muftiMenk from '../assets/muftimenk.png';
import MTJ from '../assets/MTJ.png';
import ZN from '../assets/ZN.png';
import DRIS from '../assets/dr.png';
import khanqah from '../assets/khanqah.png';
import MIBD from '../assets/MIBD.png';
import JJ from '../assets/J.png';
import MTU from '../assets/mtu.png';

export const channelData = [
    {
      img: khanqah,
      title: 'Khanqah-e-imdadia Ashrafia',
      urduTitle: 'خانقاہ امدادیہ اشرفیہ',
      channelRoute: '@khanqah',
      scholarName: 'Khanqah-e-imdadia Ashrafia',
      scholarDescription: "Khanqah Imdadia Ashrafia is in the memory of Hakeemul Ummat Mujaddid e Millat Hazrat Maulana Ashraf Ali Sahab Thanvi (RA). Khanqah is the spiritual center providing a facility for Islah-e-Nafs (Self Correction). Following the traditions of the Ehl-e-Haq (Truth Bearers), Khanqah revives the rare traditions of Sulook, Tazkia, & Tassawuf while staying clear of all kinds of Bidda’hs (innovations in the religion) and complies strictly with the rules and boundaries prescribed by the Sharia.",
      urduDescription: "خانقاہ امدادیہ اشرفیہ حکیم الامت مجدد ملت حضرت مولانا اشرف علی صاحب تھانوی رحمۃ اللہ علیہ کی یاد میں ہے۔ خانقاہ ایک روحانی مرکز ہے جو اصلاح نفس کے لیے ایک سہولت فراہم کرتا ہے۔ اہل حق کی روایات پر عمل کرتے ہوئے خانقاہ ہر قسم کی بدعات (دین میں بدعات) سے پاک رہتے ہوئے سلوک، تزکیہ اور تصوف کی نادر روایات کو زندہ کرتی ہے اور احکام کی سختی سے تعمیل کرتی ہے۔",
      mediaTracks: [
        {
          name: "Sahi ilm ke teen(3) faidey",
          urduName: "صحیح علم کے تین (3) فوائد",
          src: "https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/1564-Sahi-Ilm-ke-Teen-Faidey.mp3"
        },
        {
          name: "Rabi-ul-awwal-ki-Haqiqat",
          urduName:  "ربیع الاول کی حقیت" ,
          src: "https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Rabi-ul-awwal-ki-Haqiqat.mp3"
        },
        {
          name: "Ahlullah ki Zyarat Aankho ki Ibadat",
          urduName: "اہل اللہ کی زیارت آنکھوں کی عبادت",
          src: "https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Ahlullah-ki-Zyarat-Aankho-ki-Ibadat.mp3"
        },
        {
          name: "Akhirat ki tayyari duniya mein hi karni hai",
          urduName: "آخرت کی تیاری دنیا میں ہی کرنی ہے",
          src: "https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Akhirat-ki-tayyari-duniya-mein-hi-karni-hai.mp3"
        },
        {
          name: "Allah Ta'ala Ki Muhabbat Aur Dunia Ka Gham",
          urduName: "اللہ تعالیٰ کی محبت اور دنیا کا غم",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Allah Ta'ala Ki Muhabbat Aur Dunia Ka Gham.mp3"
        },
        {
          name: 'Buzurgon se seekhne ki chand bate',
          urduName:  "بزرگوں  سے  سیکھنے  کی  چند  باتین",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Buzurgon se seekhne ki chand bate.mp3' 
        },
        {
          name: 'Dua Kese Mangen',
          urduName: "دعا کیسے مانگے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Dua Kese Mangen.mp3' 
        },
        {
          name: 'Maghfirat Ka Mafhoom',
          urduName: "مغفرت کا مفوم",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/Maghfirat Ka Mafhoom.mp3'
        },
        {
          name: 'taqwa ky 7 Inaam',
          urduName: "تقویٰ کے ساتھ(7) انعام",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/taqwa ky saat inaamaat.mp3' 
        },
        {
          name: 'Waliullah Bannay Ka Asaan Nuskha',
          urduName: "ولی اللہ بننے کا آسان نسخہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/Khanqah-e-imdadia-Ashrafia/ Waliullah Bannay Ka Asaan Nuskha.mp3'
        },    
      ]
    },
    {
      img: mft,
      title: 'Mufti Tariq Masood',
      urduTitle: 'مفتی طارق مسعود',
      channelRoute: '@MuftiTM',
      scholarName: 'Mufti Tariq Masood',
      scholarDescription: "Tariq Masood is a Pakistani Deobandi author and Islamic scholar, who teaches at the Jamia Tur Rasheed seminary in Karachi He has authored books Including Aik se Zaid Shadiyoon ki Zaroorat Kyu.",
      urduDescription:"طارق مسعود ایک پاکستانی دیوبندی مصنف اور اسلامی اسکالر ہیں، جو کراچی کے جامعہ الرشید مدرسہ میں پڑھاتے ہیں، انہوں نے کتابیں تصنیف کی ہیں جن میں ایک سے زید شادیوں کی ضروت بھی شامل ہے۔",
      mediaTracks: [
        {
          name: "Namaz Na Chorna",
          urduName: "نماز مت چھوڑو",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Namaz Na Chorna.mp3"
        },
        {
          name: "Maut Sa Pahla Maut Ki Tayari Karlu",
          urduName: "موت سے پہلا موت کی تیاری کرلو",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Maut Se Pehle Maut Ki Tayyari Karlo.mp3"
        },
        {
          name: "Musalmano Ki Barbadi Ki Wajah",
          urduName: "مسلمانوں کی تباہی کی وجہ",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Musalmano Ki Barbadi Ki Wajah.mp3"
        },
        {
          name: "Be Namazi Ka Anjam",
          urduName: "بے نمازی کا انجام",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Be Namazi Ka Anjam.mp3"
        },
        {
          name: 'Tension lene ka nuqsan',
          urduName: "ٹینشن لینے کے نقصانات",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Tension lene ka nuqsan.mp3'
        },
        {
          name: 'Ye Kaam Karo Rizq Izzat Ke Saath Darwaze Tod Kar Aayega',
          urduName: "یہ کام کرو رزق عزت سے دروازے توڑتا ہوا آئے گا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Ye Kaam Karo Rizq Izzat Ke Saath Darwaze Tod Kar Aayega.mp3'
        },
        {
          name: 'Aakhri Jannati Ka Dilchasb kahani',
          urduName: "آخری جنت کی دلچسپ کہانی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Aakhri Jannati Ka Dilchasp Waqia.mp3'
        },
        {
          name: 'ALLAH Se Mango',
          urduName: "اللہ سے مانگنا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/ALLAH Se Mango.mp3'
        },
        {
          name: 'Ghazwa Tabook Aur Hazrat Ka’ab Bin Malik (R.A) Ka Waqia.mp3',
          urduName: "غزوہ تبوک اور حضرت ابی بن مالک رضی اللہ عنہ کا واقعہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Ghazwa Tabook Aur Hazrat Ka’ab Bin Malik (R.A) Ka Waqia.mp3'
        },
        {
          name: 'Hazrat Umar Ka Darbaar Aur 3 Sahaba Ka Waqia',
          urduName: "حضرت عمرؓ کا دربار اور تین صحابہؓ کا واقعہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Hazrat Umar Ka Darbaar Aur 3 Sahaba Ka Waqia.mp3'
        },
        {
          name: 'khamosh rehana sikhiey',
          urduName: "خاموش رہنا سیکھو",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/khamosh rehana sikhiey.mp3'
        },
        {
          name: 'Mar Kar Dobara Zinda Hone Par Tum Shaq Kyun Karte Ho',
          urduName: "تم مردوں میں سے جی اٹھنے میں کیوں شک کرتے ہو؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Mar Kar Dobara Zinda Hone Par Tum Shaq Kyun Karte Ho.mp3'
        },
        {
          name: 'Nabi ki namaz e janaza kyo nhi huyi',
          urduName: "نبی صلی اللہ علیہ وسلم کی نماز کیوں نہیں پڑھی گئی؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Nabi ki namaz e janaza kyo nhi huyi .mp3'
        },
        {
          name: 'Nabi saw Or 3 Sahaba ka Waqia',
          urduName: "نبی کریم صلی اللہ علیہ وسلم اور تین صحابہ کا قصہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Nabi saw Or 3 Sahaba ka Waqia.mp3'
        },
        {
          name: 'Success ka Formula',
          urduName: "کامیابی کا فارمولا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-tariq-masood/Success ka Formula.mp3'
        },
       
      ]
    },
    {
      img: MTJ,
      title: 'Moulana Tariq Jameel',
      urduTitle: 'مولانا طارق جمیل',
      channelRoute: '@MTJ',
      scholarName: 'Moulana Tariq Jameel',
      scholarDescription: "Moulana Tariq Jamil, is a Pakistani religious writer, scholar, and a member of the Tablighi Jamaat. The recipient of the Pride of Performance award, Moulana Tariq Jameel has been named twice in The 500 Most Influential Muslims.",
      urduDescription: "مولانا طارق جمیل، ایک پاکستانی مذہبی مصنف، عالم، اور تبلیغی جماعت کے رکن ہیں۔ پرائیڈ آف پرفارمنس ایوارڈ حاصل کرنے والے، مولانا طارق جمیل کا نام 500 بااثر مسلمانوں میں دو بار رکھا گیا ہے۔",
      mediaTracks: [
        {
          name: "Allah Sa Mango",
          urduName: "اللہ سے مانگنا",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Allah sy Mango.mp3"
        },
        {
          name: "Mout K Bad Kia Huga _ Life Changing Bayan",
          urduName: "موت کے بعد کیا ہوا _ زندگی بدلنے والا بیان",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Mout K Bad Kia Huga.mp3"
        },
        {
          name: "Sach Ki Taqat",
          urduName: "سچ کی طاقت",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Sach Ki Taqat.mp3"
        },
        {
          name: "The Way to Success",
          urduName: "کامیابی کا راستہ",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/The Way to Success.mp3"
        },
        {
          name: 'Qayamat Ke Din Nafsi Nafsi Ka Alam',
          urduName: "قیامت کے دن نفصی نفصی کا عالم",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Qayamat Ke Din Nafsi Nafsi Ka Alam.mp3'     
        },
        {
          name: 'Jung e badar 17 Ramadan',
          urduName: "جنگ بدر 17 رمضان المبارک",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Jung e badar 17 Ramadan.mp3'
        },
        {
          name: 'Jab Farishte Bhi Roye',
          urduName: "جب فرشتے بھی روتے ہیں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Jab Farishte Bhi Roye.mp3'
        },
        {
          name: 'Who Will Enter Jannah First',
          urduName: "جنت میں سب سے پہلے کون داخل ہوگا؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Who Will Enter Jannah First.mp3'
        },
        {
          name: 'Hazrat Khalid Bin Waleed(R.A) Battle of Yarmouk',
          urduName: "حضرت خالد بن ولید رضی اللہ عنہ جنگ یرموک",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Hazrat Khalid Bin Waleed(R.A) Battle of Yarmouk .mp3'
        },
        {
          name: 'Hazrat Bilal Habshi R.A Ka Waqia',
          urduName: "حضرت بلال حبشی رضی اللہ عنہ کا واقعہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Hazrat Bilal Habshi R.A Ka Waqia.mp3'
        },
        {
          name: 'Emaan Ka Noor Kaha Milega',
          urduName: "کہاں ملے ایمان کی روشنی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Emaan Ka Noor Kaha Milenga.mp3'
        },
        {
          name: 'Ek Baap Ka Dard Bhara Waqia',
          urduName: "ایک باپ کی دردناک کہانی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Ek Baap Ka Dard Bhara Waqia.mp3'
        },
        {
          name: 'Do one Thing Before Sleeping',
          urduName: "سونے سے پہلے ایک کام کریں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Do one Thing Before Sleeping.mp3'
        },
        {
          name: 'Allah love for Prophet Muhammed PBUH',
          urduName: "اللہ تعالیٰ کو حضرت محمد صلی اللہ علیہ وسلم سے محبت ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Allah love for Prophet Muhammed PBUH.mp3'
        },
        {
          name: 'Allah ko kese Razi kare',
          urduName: "اللہ کو کیسے راضی کریں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-tariq-jameel/Allah ko kisa Razi kara.mp3'
        },    
       
      ]
    },
    {
      img: muftiMenk,
      title: 'Mufti Menk',
      urduTitle: 'مفتی مینک',
      channelRoute: '@menk',
      scholarName: 'Mufti Menk',
      scholarDescription: "Ismail ibn Musa Menk, also known as Mufti Menk, is a Zimbabwean Islamic scholar. He is the Grand Mufti and head of the fatwa department of the Zimbabwean Muslim community, which represents less than 1% of the country's total population.",
      urduDescription: "اسماعیل بن موسی مینک جو مفتی مینک صاحب کے نام سے بھی جانے جاتے ہیں، زمبابوے کے اسلامی اسکالر ہیں۔ وہ مفتی اعظم ہیں اور زمبابوے کی مسلم کمیونٹی کے فتویٰ کے شعبے کے سربراہ ہیں، جو ملک کی کل آبادی کے 1% سے بھی کم کی نمائندگی کرتے ہیں۔",
      mediaTracks: [
        {
          name: "ALLAH has a beautiful plan for you!",
          urduName: "!اللہ کے پاس آپ کے لیے ایک خوبصورت منصوبہ ہے",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/ALLAH HAS A BEAUTIFUL PLAN FOR YOU!.mp3"
        },
        {
          name: "How to Protect Yourself from Evil",
          urduName: "اپنے آپ کو برائی سے کیسے بچائیں",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/How to Protect Yourself from Evil.mp3"
        },
        {
          name: "Importance of TAHAJJUD",
          urduName: "تہجد کی اہمیت",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Importance of TAHAJJUD.mp3"
        },
        {
          name: "This 1 verse mentions 6 life changing things!",
          urduName: "!یہ 1 آیت 6 زندگی بدلنے والی چیزوں کا ذکر کرتی ہے",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/This 1 verse mentions 6 life changing things!.mp3"
        },
        {
          name: 'Trust Allah! He is in Control',
          urduName: "!اللہ پر بھروسہ رکھو",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Trust Allah! He is in Control.mp3'
        },
        {
          name: "Keep Moving. Don't Look Back",
          urduName: "چلتے رہو. پیچھے مڑ کر مت دیکھو",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Keep Moving. Don't Look Back.mp3"
        },
        {
          name: 'Allah is greater than your problem.',
          urduName: "اللہ تمہاری پریشانی سے بڑا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Allah is greater than your problem.mp3'
        },
        {
          name: 'Comfort in times of crisis',
          urduName: "بحران کے وقت آرام",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Comfort in times of crisis.mp3'
        },
        {
          name: 'Heal your Depression',
          urduName: "اپنے ڈپریشن کو ٹھیک کریں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Heal your Depression.mp3'
        },
        {
          name: 'Importance of Ramadaan',
          urduName: "رمضان المبارک کی اہمیت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Importance of Ramadaan.mp3'
        },
        {
          name: 'Miracles of Praying',
          urduName: "دعا کے معجزات",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Miracles of Praying.mp3'
        },
        {
          name: 'Silence is Golden, The Power of being Silent',
          urduName: "خاموشی سنہری ہے، خاموش رہنے کی طاقت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Silence is Golden, The Power of being Silent.mp3'
        },
        {
          name: 'Too Lazy To Pray',
          urduName: "نماز میں بہت سستی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/Too Lazy To Pray.mp3'
        },
        {
          name: 'WHY IS MY LIFE SO SAD, MISERABLE AND LONELY',
          urduName: "میری زندگی اتنی اداس، دکھی اور تنہا کیوں ہے؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-menk/WHY IS MY LIFE SO SAD, MISERABLE AND LONELY.mp3'
        },
       
      ]
    },
    {
      img: MIBD,
      title: 'Moulana Ibadullah',
      urduTitle: 'مولانا عباداللہ',
      channelRoute: '@IBD',
      scholarName: 'Moulana Ibadullah',
      scholarDescription: "Moulana Ibadullah, is a Pakistani religious writer and a member of the Tablighi Jamaat.",
      urduDescription: "مولانا عباد اللہ، ایک پاکستانی مذہبی مصنف اور تبلیغی جماعت کے رکن ہیں۔",
      mediaTracks: [
        {
          name: "Astaghfar Ki Taqat Aur Fazilat",
          urduName: "استغفار کی طاقت اور فضیلت",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Astaghfar Ki Taqat Aur Fazilat.mp3"
        },
        {
          name: "Duniya aur akhirat ki zindagi",
          urduName: "دنیا اور آخرت کی زندگی",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Duniya aur akhirat ki zindagi.mp3"
        },
        {
          name: "Ghussa khatm karain",
          urduName: "غصہ چھوڑ دو",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Ghusa khatm karain.mp3"
        },
        {
          name: "Musalman ghaflat ki neend so raha hai",
          urduName: "مسلمان گفلت کی نیند سو رہا ہے",
          src: "https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Musalman ghaflat ki neend so raha hai.mp3"
        },
        {
          name: '3 person in the responsibility of Allah',
          urduName: "3 شخص اللہ کی ذمہ داری میں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/3 person in the responsibility of Allah.mp3'
        },
        {
          name: 'Azaan Me 6 Paigamat Hen',
          urduName: "اذان میں 6 پیغامات ہیں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Azaan Me 6 Paigaam Hen.mp3'
        },
        {
          name: 'Maghfirat ki Raat',
          urduName: "بخشش کی رات",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Maghfirat ki Raat.mp3'
        },
        {
          name: 'Razaq Allah ki zaat hai',
          urduName: "رازق اللہ کی ذات ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Razaq Allah ki zaat hai.mp3'
        },
        {
          name: 'Rehmatain Aur Barkatain Ainge',
          urduName: "رحمتیں اور برکتیں اینگی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Rehmatain Aur Barkatain Ainge.mp3'
        },
        {
          name: 'Ye Duniya khel tamasha hai',
          urduName: "یہ دنیا ایک کھیل ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/molana-Ibadullah/Ye Duniya khel tamasha hai.mp3'
        },
       
      ]
    },
    {
      img: JJ,
      title: 'Junaid Jamshed',
      urduTitle: 'جنید جمشید',
      channelRoute: '@J.',
      scholarName: 'Junaid Jamshed',
      scholarDescription: "Junaid jamshed was a Pakistani singer-songwriter, television personality, fashion designer, actor, and preacher.As early as 1999, the media began speculating about Jamshed's drift from music soon after the Vital Signs faded away. The speculations soon died after Jamshed released his solo albums and continued world tours. After 2001, Jamshed disappeared from the public eye and avoided media attention",
      urduDescription: "جنید جمشید ایک پاکستانی گلوکار، نغمہ نگار، ٹیلی ویژن کی شخصیت، فیشن ڈیزائنر، اداکار، اور مبلغ تھے۔ 1999 کے اوائل میں، وائٹل سائنز کے ختم ہونے کے فوراً بعد میڈیا نے جمشید کے موسیقی سے ہٹ جانے کے بارے میں قیاس آرائیاں شروع کر دیں۔ یہ قیاس آرائیاں جلد ہی دم توڑ گئیں جب جمشید نے اپنے سولو البمز جاری کیے اور دنیا کے دورے جاری رکھے۔ 2001 کے بعد جمشید عوام کی نظروں سے اوجھل ہو گئے اور میڈیا کی توجہ سے بچ گئے۔",
      mediaTracks: [
        {
          name: "Allah key liye apny nafs(ego) key khilaf Jihad",
          urduName: "اللہ کی رضا کے لیے نفس کے خلاف جہاد کرنا",
          src: "https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Allah Key Liye Apny Nafs Key Khilaf.mp3"
        },
        {
          name: "Allah Ki Madad Bandy Ke Sath",
          urduName: "اللہ کی مدد بندے کے ساتھ ھے",
          src: "https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Allah Ki Madad Bandy Ke Sath.mp3"
        },
        {
          name: "Sabar krna seekhain",
          urduName: "صبر کرنا سیکھیں",
          src: "https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Sbr Krna Sekh Lo.mp3"
        },
        {
          name: "When Abu Jahl began to die",
          urduName: "جب ابوجہل مرنے لگا",
          src: "https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/When Abu Jahl began to die.mp3"
        },
        {
          name: '4 Moqey Ha Jin Per Allah Muskraty hain',
          urduName: "ایسے مواقع جن پر اللہ مسکراتا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/4 Moqey Ha Jin Per Allah Muskraty.mp3'
        },
        {
          name: 'Allah Ney Insan Ko Zamen Per',
          urduName: "اللہ نے انسان کو زمین پر اپنا خلیفہ بنا کر بھیجا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Allah Ney Insan Ko Zamen Per.mp3'
        },
        {
          name: 'Allah Pe Yaqen Rukh',
          urduName: "اللہ پر یقین رکھو",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Allah Pe Yaqen Rukh.mp3'
        },
        {
          name: 'Dil Main Khwahshait',
          urduName: "دل میں خواہشات",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Dil Main Khwahshait.mp3'
        },
        {
          name: 'Duniya Dhoky Ka Ghr Hai',
          urduName: "دنیا دھوکے کا گھر ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Duniya Dhoky Ka Ghr Hai.mp3'
        },
        {
          name: 'Hamara Subse Bara Qasor Kia Hai',
          urduName: "ہمارا سب سے بڑا قصور کیا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Hamara Subse Bara Qasor Kia Hai.mp3'
        },
        {
          name: 'Insan ko duniya mei bhejne ka maqsad',
          urduName: "انسان کو دنیا میں بھیجنے کا مقصد",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Insan ko duniya mei bhejne ka maqsad.mp3'    
        },
        {
          name: 'Jis Qoam Mein Be Hayai Aaam Ho',
          urduName: "جس قوم میں  بے حیایئ عام ہو",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Jis Qoam Mein Be Hayai Aaam Ho.mp3'
        },
        {
          name: 'Jis Shuks Ney Fajar Ki Namaz Chori.mp3',
          urduName: "جس شخص نے فجر کی نماز چھوڑی",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Jis Shuks Ney Fajar Ki Namaz Chori.mp3'
        },
        {
          name: 'Kamyabi Ki Shaqal Or Iski Haqeqat.mp3',
          urduName: "کامیابی کا ظہور اور اس کی حقیقت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Kamyabi Ki Shaqal Or Iski Haqeqat.mp3'
        },
        {
          name: 'Yeah Dua Qasrat Sey Mango Kro',
          urduName: "یہ دعا قصرت سے مانگا کرو",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/junaid-jamshed/Yeah Dua Qasrat Sey Mango Kro.mp3'
        },

      ]
    },
    {
      img: DRIS,
      title: 'DR. Israr Ahmed',
      urduTitle: 'ڈاکٹر اسرار احمد',
      channelRoute: '@IsrarAhmed',
      scholarName: 'DR. Israr Ahmed',
      scholarDescription: "Israr Ahmad was a Pakistani Islamic theologian, philosopher, and Islamic scholar who was followed particularly in South Asia as well as by South Asian Muslims in the Middle East, Western Europe, and North America. He was the founder of Tanzeem-e-Islami, an offshoot of the Jamaat-e-Islami.",
      urduDescription: "ڈاکٹر اسرار احمد ایک پاکستانی اسلامی ماہر الہیات، فلسفی، اور اسلامی اسکالر تھے جن کی پیروی خاص طور پر جنوبی ایشیا کے ساتھ ساتھ مشرق وسطیٰ، مغربی یورپ اور شمالی امریکہ میں جنوبی ایشیائی مسلمانوں نے کی۔ وہ تنظیم اسلامی کے بانی تھے جو کہ جماعت اسلامی کی شاخ ہے۔",
      mediaTracks: [
        {
          name: "Tawakkal on Allah",
          urduName: "اللہ پر بھروسہ رکھیں",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/ALLAH PER TAWAKAL.mp3"
        },
        {
          name: "Discipline is very important for success",
          urduName: "کامیابی کے لیے نظم و ضبط بہت ضروری ہے",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Discipline is very important for success.mp3"
        },
        {
          name: "Hazrat Umar(R.A) ka Dore khilafat",
          urduName: "حضرت عمر رضی اللہ عنہ کا دورہ خلافت",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Hazrat Umar(R.A) Ka Dore Khilafat.mp3"
        },
        {
          name: "Reality Of Life",
          urduName: "زندگی کی حقیقت",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Reality Of Life.mp3"
        },
        {
          name: 'Allah ki mohaabat',
          urduName: "اللہ کی محبت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Allah ki mohaabat.mp3'
        },
        {
          name: 'Aurat ki Awaz ka Parda',
          urduName: "عورت کی آواز کا پردہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Aurat ki Awaz ka Parda.mp3'
        },
        {
          name: 'Azmaish ki haqeqat',
          urduName: "آزمائش کی حقیقت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Azmaish ki haqeqat.mp3'
        },
        {
          name: 'Dil ka sakoon',
          urduName: "دل کا سکون",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Dil ka sakoon.mp3'
        },
        {
          name: 'How to control your mind',
          urduName: "اپنے دماغ کو کیسے کنٹرول کریں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/How to control your mind.mp3'
        },
        {
          name: 'Muqaddar Mein Jo Likha Hai Zrore Milay Ga',
          urduName: "آپ کو وہ ضرور ملے گا جو آپ کی قسمت میں لکھا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Muqaddar Mein Jo Likha Hai Zrore Milay Ga.mp3'
        },
        {
          name: 'Namaz Mai Dil Q Nahi lagta',
          urduName: "نماز میں دل کیوں نہیں لگتا؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Namaz Mai dil Q nhi lagta.mp3'
        },
        {
          name: 'Qayamat Ki 7 Bari Nishaniyan',
          urduName: "قیامت کی 7 بڑی نشانیاں",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Qayamat Ki 7 Bari Nishaniyan.mp3'
        },
        {
          name: 'Ramzan ki fazilat',
          urduName: "رمضان کی فضیلت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Ramzan ki fazilat.mp3'
        },
        {
          name: 'Surah Ar-Rahman Mein 4 Ajeeb Haqaiq',
          urduName: "سورہ الرحمن کے بارے میں 4 عجیب و غریب حقائق",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Surah Ar-Rahman Mein 4 Ajeeb Haqaiq.mp3'
        },
        {
          name: 'Zindagi mei kese khush rahe',
          urduName: "زندگی میں کیسے خوش رہیں؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-israr-ahmed/Zindagi mei kese khush rahe.mp3'
        },

      ]
    },
    {
      img: ZN,
      title: 'Zakir Naik',
      urduTitle: 'ذاکر نائیک',
      channelRoute: '@zakirnaik',
      scholarName: 'Zakir Naik',
      scholarDescription: "Zakir Abdul Karim Naik is an Indian Islamic televangelist and public orator who focuses on comparative religion. He is the founder and president of the Islamic Research Foundation and the Peace TV Network.",
      urduDescription: "ذاکر عبدالکریم نائیک ایک ہندوستانی اسلامی ٹیلی ویژن اور عوامی خطیب ہیں جو تقابلی مذہب پر توجہ دیتے ہیں۔ وہ اسلامک ریسرچ فاؤنڈیشن اور پیس ٹی وی نیٹ ورک کے بانی اور صدر ہیں۔",
      mediaTracks: [
        {
          name: "Hijab ka hukum",
          urduName: "حجاب کا حکم",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Hijab ka hukum.mp3"
        },
        {
          name: "Is music haram in islam?",
          urduName: "اسلام میں موسیقی حرام ہے؟",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Is Music haram in islam.mp3"
        },
        {
          name: "Namaz na parhny ki saza",
          urduName: "نماز نہ پڑھنے کی سزا",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Namaz Na Parhny ki Saza.mp3"
        },
        {
          name: "Why dawah is important",
          urduName: "دعوت کیوں ضروری ہے؟",
          src:  "https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Why dawah is important.mp3"
        },
        {
          name: 'A 9 year Old Christian Boy Asks a Very Difficult Question to Dr Zakir Naik',
          urduName: "ایک 9 سالہ عیسائی لڑکے نے ڈاکٹر ذاکر نائیک سے بہت مشکل سوال کر دیا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/A 9 year Old Christian Boy Asks a Very Difficult Question to Dr Zakir Naik.mp3'
        },
        {
          name: 'A Chinese Asks Why Does Not Allah Show Himself in Islam',
          urduName: "ایک چینی پوچھتا ہے کہ اللہ خود کو اسلام میں ظاہر کیوں نہیں کرتا؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/A Chinese Asks Why Does Not Allah Show Himself in Islam.mp3'
        },
        {
          name: 'Christian Lady had Heated Exchange with Dr. Zakir about Jesus',
          urduName: "ڈاکٹر ذاکر کے ساتھ مسیحی خاتون کا عیسیٰ کے بارے میں گرما گرم تبادلہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Christian Lady had Heated Exchange with Dr. Zakir about Jesus.mp3'
        },
        {
          name: 'Deen or duniya mein hamen kaise Islam per chalna chahie',
          urduName: "ہمیں  دین اور دنیا  میں اسلام پر کیسے چلنا چاہیے؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Deen or duniya mein hamen kaise Islam per chalna chahie.mp3'
        },
        {
          name: 'Dr Zakir Naik a challenge between Hindu',
          urduName: "ڈاکٹر ذاکر نائیک ہندوؤں کے درمیان چیلنج",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Dr Zakir Naik a challenge between Hindu.mp3'
        },
        {
          name: 'Mary Challenges Dr Zakir that Bible mentions Jesus (pbuh) is God',
          urduName: "مریم نے ڈاکٹر ذاکر کو چیلنج کیا کہ بائبل میں عیسیٰ (علیہ السلام) کا ذکر خدا ہے",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Mary Challenges Dr Zakir that Bible mentions Jesus (pbuh) is God.mp3'
        },
        {
          name: 'Why Did not God Create Only One Religion',
          urduName: "خدا نے صرف ایک مذہب کیوں نہیں بنایا؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/dr-zakir-nayak/Why Did not God Create Only One Religion.mp3'
        },

      ]
    },
    {
      img: MTU,
      title: 'Mufti Taqi Usmani',
      urduTitle: 'مفتی تقی عثمانی',
      channelRoute: '@taqiusmani',
      scholarName: 'Mufti Taqi Usmani',
      scholarDescription: "Muhammad Taqi Usmani (born 5 October 1943) is a Pakistani Islamic scholar and former judge who is the current president of the Wifaq ul Madaris Al-Arabia and the vice president and Hadith professor of the Darul Uloom Karachi. An intellectual leader of the Deobandi movement, he has authored 143 books in Urdu, Arabic and English, including a translation of the Qur'an in both English and Urdu as well a 6-volume commentary on the Sahih Muslim in Arabic",
      urduDescription: "مفتی تقی عثمانی (پیدائش: 5 اکتوبر 1943) ایک پاکستانی اسلامی اسکالر اور سابق جج ہیں جو وفاق المدارس العربیہ کے موجودہ صدر اور دارالعلوم کراچی کے نائب صدر اور حدیث کے پروفیسر ہیں۔ دیوبندی تحریک کے ایک دانشور رہنما، انہوں نے اردو، عربی اور انگریزی میں 143 کتابیں تصنیف کی ہیں، جن میں انگریزی اور اردو دونوں میں قرآن کا ترجمہ اور عربی میں صحیح مسلم کی 6 جلدوں پر مشتمل تفسیر بھی شامل ہے۔",
      mediaTracks: [
        {
          name: "Allah Ki Taraf Say Momin Par Azmaish",
          urduName: "اللہ کی طرف سے مومن پر آزمائش",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Allah Ki Taraf Say Momin Par Azmaish.mp3"
        },
        {
          name: "Hamari Nojawan Nasal Ka Sab Se Bara Masla",
          urduName: "ہماری نوجوان نسل کا سب سے بڑا مسئلہ",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Hamari Nojawan Nasal Ka Sab Se Bara Masla.mp3"
        },
        {
          name: "Kabhi Mayoos Nahi hona",
          urduName: "کبھی مایوس نہ ہوں",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Kbhi Mayoos Nahi hona.mp3"
        },
        {
          name: "Marne Ka Baad Kaam Ane Wale Amaal",
          urduName: "مرنے کے بعد کام آنے  والے عمل",
          src: "https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/marne k baad kaam ane wale amal.mp3"
        },
        {
          name: 'Deen Naam Hai 5 Shobon Ka',
          urduName: "دین نام ہے 5 شعبون کا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Deen Name Hai 5 Shobon Ka.mp3'  
        },
        {
          name: 'Important Message for Students',
          urduName: "طلباء کے لیے اہم پیغام",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Important Message for Students.mp3'
        },
        {
          name: 'KABAR MEIN MUSLALMAN AUR GAIR MUSLIM KE SATH KYA HO GA',
          urduName: "قبر میں مسلم اور غیر مسلم سا تھ  کیا ہوگا؟",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/KABAR MEIN MUSLALMAN AUR GAIR MUSLIM KE SATH KYA HO GA.mp3'
        },
        {
          name: 'Musalmano ko taqleef dene ka wabaal',
          urduName: "مسلمان کو تکلیف دینے کا وابال",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Musalmano ko taqleef dene ka wabaal.mp3'
        },
        {
          name: 'Namaz mei khayalat',
          urduName: "نماز میں خیالات",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Namaz mei khayalt.mp3'
        },
        {
          name: 'Qabooliat amaal ki 3 sharait',
          urduName: "قبولیت اعمال کی 3 شرائت",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Qabooliat amaal ki 3 sharait.mp3'
        },
        {
          name: 'Ramazan Is Tarah Guzaro Taqwa Hasil Hoga',
          urduName: "رمضان اس طرح گزارو تمہیں تقویٰ ملے گا",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Ramazan Is Tarah Guzaro Taqwa Hasil Hoga.mp3'
        },
        {
          name: 'Ramzan Gafil Bando Ko ALLAH K Qareeb Karne Ka Mahina',
          urduName: "رمضان غافل بندو کو اللہ کے قریب کرنے کا مہینہ",
          src:  'https://d3aw1qwnchob5o.cloudfront.net/mufti-taqi-usmani/Ramzan Gafil Bando Ko ALLAH K Qareeb Karne Ka Mahina.mp3'
        },
       
      ]
    },
];