import React from 'react'
import Header from '../components/Header';
import CheckOutForm from '../components/Checkout';
import Footer from '../components/Footer';

const Checkout = () => {
  return (
    <>
    {/* <Header/> */}
    <CheckOutForm/>
    <Footer/>
    </>
  )
}

export default Checkout;